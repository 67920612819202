import { createSlice } from "@reduxjs/toolkit";

const ActivitySlice = createSlice({

    name:'Activity',
    initialState:{
        Activities:[],
        AllBoardImages:[],
    },

    reducers:{
        AllUsers : (state, action)=>{
         state.Activities=action.payload;
        },
        setAllBoardImages : (state, action)=>{
            state.AllBoardImages=action.payload;
           },

        AllActivities : (state, action) =>{
            state.Activities = action.payload
        },
        RemoveActiviiesfromStore : (state)=>{
            state.Activities=[];

        },
        IremboServicesList : (state, action )=>{
            state.Activities=action.payload;
        },
        RRAServicesList : (state, action)=>{
            state.Activities=action.payload;
        },
        AllRequestedCars : (state, action)=>{
            state.Activities=action.payload;
        },
        AllRequestedHouses : (state, action)=>{
            state.Activities = action.payload;

        },
        AllRequestedElectronicDevices : (state, action)=>{
            state.Activities= action.payload;

        },
        AllInOneRequestedFoodAndDrinks : (state, action)=>{
            state.Activities = action.payload;

        },
        AllRequestedFashions : (state, action)=>{
            state.Activities = action.payload;

        }
    }
});

export const {AllActivities, RemoveActiviiesfromStore, IremboServicesList, RRAServicesList, AllUsers,AllRequestedCars, AllRequestedFashions,AllRequestedElectronicDevices,AllRequestedHouses,AllInOneRequestedFoodAndDrinks,setAllBoardImages} = ActivitySlice.actions;

export const SelectActivity = (state) => state.Activity.Activities;
export const SelectBoardImages = (state) => state.Activity.AllBoardImages;

export default ActivitySlice.reducer;