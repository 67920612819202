import React, { useEffect, useState } from 'react';
import emailjs from 'emailjs-com';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Link from './Link';
import ClipLoader from "react-spinners/CircleLoader";
import OtherModal from 'react-modal';
import moment from 'moment';

function Footer() {
  const[FirstName, setFirstName]=useState('');
  const[LastName, setLastName]=useState('');
  const[PhoneNumber, setPhoneNumber]=useState();
  const[Email, setEmail]=useState('');
  const[Message, setMessage]=useState('');
  const[loadingModal, setloadingModal]=useState(false);

  const SendEmail = async(e)=>{
    setloadingModal(!loadingModal);
    if(Message!='' || FirstName!='' || Email!=''){
      e.preventDefault();
      emailjs.sendForm('service_ue9l4vt', 'template_4o3xtwa', e.target, 'Kf6WHvdelAA4yrlf2')
     .then((result) => {
      setFirstName('');
      setEmail('');
      setPhoneNumber(0);
      setMessage('');
      setloadingModal(false);
         return toast.success(`${result.text} : Message has been Received`);

     }, (error) => {
         console.log(error.text);
     });
     
    }
    else{
      toast.error("Please fill in your Names, Email And Message before send")
     

    }
    
 }
  return (
    <div className=' flex flex-row items-center bg-gray-300 h-auto small:h-fit Phone:h-fit justify-between p-10 Phone:items-center tablet:items-center small:items-center small:flex-col Phone:flex-col laptop:items-stretch'>
       <ToastContainer position="top-center" limit={1} />
        <div className=' pr-40 px-40 flex flex-col items-center small:px-96 small:pr-96'>
            <h1 className='font-extrabold text-gray-500 mb-16 mt-4 text-6xl text-center small:px-40 small:pr-40 Phone:px-40 Phone:pr-40 laptop:p-1 tablet:p-5'>Engage With Us</h1>

            <h1 className='font-semibold text-gray-500 mb-8 mt-4 text-lg text-center small:p-20 Phone:p-20 laptop:p-1 tablet:p-5'>Phone Number : +250788704084     /     Email : gogisenyitours@gmail.com</h1>
            <div>
            <form onSubmit={SendEmail}>
              <div className='flex laptop:flex-row items-center mb-4 mt-1 Phone:flex-col small:flex-col tablet:flex-col '> 
        <div className='flex flex-row justify-between flex-1'>
          <input
           className='border p-2 rounded-md mt-1 mb-2 outline-none'
          value={FirstName}
          onChange={(e)=>setFirstName(e.target.value)}
          placeholder='Names' name='name'/>
      </div>
      <div className='flex flex-col flex-1'>
        <input
           className='border p-2 rounded-md mt-1 mb-2 mx-2 outline-none'
          value={Email}
          type='email'
          onChange={(e)=>setEmail(e.target.value)}
          placeholder='Email' name='email'/>
      </div>
      <div className='flex flex-col flex-1'>
        <input
           className='border p-2 rounded-md mt-1 mb-2 mx-2 outline-none '
          value={PhoneNumber}
          type='number'
          onChange={(e)=>setPhoneNumber(e.target.value)}
          placeholder=' Phone'/>
      </div>

              </div>
    
      <div className='flex flex-col items-start'>
        <textarea
           className='border p-4 rounded-md mt-1 mb-2 w-full self-center small:max-w-fit Phone:max-w-fit laptop:max-w-full tablet:max-w-fil h-32 outline-none'
          value={Message}
          name='message'
          onChange={(e)=>setMessage(e.target.value)}
          placeholder='Your Message'/>
      </div>
      <div className='mt-2 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-72 mr-72 w-auto cursor-pointer'>
       <input type='submit'
       className='cursor-pointer text-center sself-center'
       disabled={Message==""?true:false} value={'send'}/>
      </div>
        </form>
            </div>
        </div>
        <div className='flex self-center'>
        <Link/>
        </div>

          {/* cpy right */}
          <div className='w-full p-2 bg-green-600'>
        <h1 className='font-semibold text-gray-200  text-center'>Copyright &copy; {`${moment(new Date()).format('YYYY')}`} Go Gisenyi Tours Ltd</h1>
        </div>
        
        <OtherModal
  className=' bottom-1/2 top-64 right-5 left-1/2 sticky z-50 p-5 w-fit h-fit pr-4 px-4'

     header={'Edit'}
     isOpen={loadingModal}
     appElement={document.getElementById('app')}
     ariaHideApp={false}>
<div className='flex  flex-1 self-center items-center justify-center'>
<ClipLoader
color='cyan'
size={100}
/>
</div>
 </OtherModal>


    </div>
  )
}

export default Footer
