import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActivityNames, SelectActivityName } from '../../features/ActivityNameSlice';
import { Selectuser } from '../../features/UserSlice';
import Moment from 'moment';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { AllActivities, AllUsers, IremboServicesList, AllRequestedCars, AllRequestedElectronicDevices, AllRequestedHouses, AllInOneRequestedFoodAndDrinks, AllRequestedFashions, SelectActivity} from '../../features/ActivitySlice';
import { Dbconn } from '../ConnectionToDatabase/Connect';


function NavBar() {
const [UserRight, setUserRight]=useState('');
const[UserNames, setUserNames]=useState('');
const User =useSelector(Selectuser);
const ActivityName=useSelector(SelectActivityName);
const[Change, setChange]=useState(false);
const dispatch = useDispatch();
const Activity = useSelector(SelectActivity);

useEffect(()=>{
    if(User!=null){
        setUserRight(User.User.Category);
        setUserNames(User.User.FirstName + ' ' + User.User.LastName);
        console.log(UserRight);
    }
    else{
        setUserRight('');
    }
},[User, UserRight]);


// Administrator getting data from Database

let Posts=[];
let Users=[];
useEffect(()=>{
  (async()=>{
        await Dbconn.collection('DataSecurity').doc('UsersCridentials').collection('Users').onSnapshot(
            (snapshot)=>{
                Users=[];
                if(!snapshot.empty){
                    snapshot.forEach((doc)=>{
                        let value={
                            collection:'DataSecurity',
                            collection_Doc:'UsersCridentials',
                            Next_Collection:'Users',
                            DocumentId:doc.id,
                            FirstName:doc.data().User.FirstName,
                            LastName:doc.data().User.LastName,
                            PhoneNumber:doc.data().User.Phone_Number,
                            Email:doc.data().User.Email,
                            Category:doc.data().User.Category,
                            Title:doc.data().User.Title,
                            Profile:doc.data().User.Profile
                        }
    
                         if(value!=null && value!='' && value!=undefined){
                            Users.push(value)
                         }
                    })
    }}
        )
 

  })();
},[Users,Posts,ActivityName]);

useEffect(()=>{(async()=>{
    
})
();
},[]);

  return (
    <div className=' rounded-lg'>
        {User!=null?
         <div className='flex justify-between border  m-1 rounded-t h-28 bg-teal-100 pt-4 px-10 pr-10'>
         <AccountCircleIcon />
         <div className='flex items-start flex-col'>
         <h1 className='font-bold text-xs'>{UserNames}</h1>
         <h1 className='font-bold text-sm'>{UserRight}</h1>
         <h1>{User!==null? User.User.Phone_Number : null}</h1>
         </div>
          
     </div>
     :null}

    <div className=' h-96 mb-10'>
{
    UserRight==='Administrator'?
    <div className='pb-5'>

        {/* User information */}
        <div>
            <h1 className='text-1xs font-bold mx-1 mr-1  mt-2 mb-3 text-left'>Users</h1>
            <div>
                <h1 
                onClick={()=>{
                    dispatch(ActivityNames('Users'));  
                    dispatch(AllUsers(Users));  
                }}
                className='text-xs font-bold text-left mx-1 mt-1 mb-1 cursor-pointer text-gray-400 hover:bg-gray-600 hover:p-2 hover:mt-2'>All User</h1>

<h1 
                onClick={()=>{
                    dispatch(ActivityNames('Add-Users'));
                
                }}
                className='text-xs font-bold text-left mx-1 mt-1 mb-1 cursor-pointer text-gray-400 hover:bg-gray-600 hover:p-2 hover:mt-2'>Add Users</h1>
     </div>
        </div>

  {/* All Product */}
  <div>
            <h1 className='text-1xs font-bold mx-1 mr-1  mt-2 mb-3 text-left'>All Journey Posts</h1>
            <div>
                <h1 
                onClick={()=>{
                    dispatch(ActivityNames('All-Posts'));   
                    dispatch(AllActivities(Posts));
                   
                }}
                className='text-xs font-bold text-left mx-1 mt-1 mb-1 cursor-pointer text-gray-400 hover:bg-gray-600 hover:p-2 hover:mt-2'>All Posts</h1>

<h1 
                onClick={()=>{
                    dispatch(ActivityNames('Post-a-Journey'));

                }}
                className='text-xs font-bold text-left mx-1 mt-1 mb-1 cursor-pointer text-gray-400 hover:bg-gray-600 hover:p-2 hover:mt-2'>Post a Journey</h1>

         </div>

         <div className='mt-2'>
                <h1 
                onClick={()=>{
                    dispatch(ActivityNames('ChangeBoard_Images'));

                }}
                className='text-xs font-bold text-left mx-1 mt-1 mb-1 cursor-pointer text-gray-400 hover:bg-gray-600 hover:p-2 hover:mt-2'>Change Home Board Images</h1>

         </div>
        </div> </div>
    :null   }

    </div>
    </div>
  )
}

export default NavBar