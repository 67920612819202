import { createSlice } from "@reduxjs/toolkit";

const UserSlice = createSlice({
    name:'User',
    initialState:{
        user:null,
    },
    reducers:{
        UserLogin : (state, action)=>{
            state.user= action.payload;

        },

        Userlogout : (state)=>{
            state.user = null;

        }
    }

});

export const {UserLogin, Userlogout}=UserSlice.actions;
export const Selectuser = (state) => state.User.user;
export default UserSlice.reducer;