import { Button } from '@mui/base';
import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import emailjs from 'emailjs-com';
import 'react-toastify/dist/ReactToastify.css';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import SlideImg1 from '../Images/HomePage.jpeg';
import One from '../Images/one.jpeg';
import Two from '../Images/two.jpeg';
import Three from '../Images/three.jpeg';
import Four from '../Images/four.jpeg';
import Five from '../Images/five.jpeg';
import Six from '../Images/six.jpeg';
import Seven from '../Images/seven.jpeg';
import Eight from '../Images/eight.jpeg';
import Nine from '../Images/nine.JPG';
import Footer from './Footer';

const spanStyle = {
  padding: '20px',
  color: '#FFFFFF',
  margintTop:'20px'

}

const divStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundSize: 'fit',
  height:'400px',
  width:'100%'
 

}

const slideImages = [
  {
    url:SlideImg1,
    caption: 'THE BEST WAY TO SEE AFRICA!'
  },
  {
    url: Four,
    caption: 'RUBAVU BEST AND BERY TOURISTIC DISRTICT'
  },
  {
    url: Six,
    caption: 'OUR DIFFERENT QUATER VIEW'
  },
];

function ContactUs() {
  const[FirstName, setFirstName]=useState('');
  const[LastName, setLastName]=useState('');
  const[PhoneNumber, setPhoneNumber]=useState();
  const[Email, setEmail]=useState('');
  const[Message, setMessage]=useState('');

  const SendEmail = async(e)=>{
     e.preventDefault();
       emailjs.sendForm('gmail01', 'template_t4pst0i', e.target, '67VIsJmduvSK38Xv-')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  }
  return (
    <div className='laptop:mx-16 laptop:mr-16 laptop:w-auto small:w-full Phone:w-full tablet:w-full'>
      <div className='w-full border border-solid mt-1'>
        <div>
        <Slide>
         {slideImages.map((slideImage, index)=> (
            <div key={index}>
              <div style={{ ...divStyle, 'backgroundImage': `url(${slideImage.url})` }}>
                <span style={{...spanStyle, fontSize:'40px', fontWeight:'bold', margintTop:'30px'}}>{slideImage.caption}</span>
              </div>
            </div>
          ))}
        </Slide>

        </div>
        <ToastContainer position="top-center" limit={1} />
      </div>
      <div className='flex flex-row justify-between small:flex-col Phone:flex-col tablet:flex-col laptop:flex-row'>
      <div className='w-1/2 self-start border p-2 mt-10  mx-2 phone:w-full small:w-full tablet:w-full laptop:w-1/2'>
      <label className='font-bold text-2xl mb-2 text-gray-600'> Leave your Message we will contact you soon</label>
      <form onSubmit={SendEmail}>
      <div className='flex items-start flex-col'>
        
        <label className='font-bold text-2xl mb-2 text-gray-600'> Your Names</label>
        <div className='flex flexx-row justify-between'>
          <input
           className='border p-2 rounded-md mt-1 mb-2 w-1/2 mr-5'
          value={FirstName}
          onChange={(e)=>setFirstName(e.target.value)}
          placeholder='Your First Name' name='name'/>
          <input
           className='border p-2 rounded-md mt-1 mb-2 w-1/2 mx-5'
          value={LastName}
          onChange={(e)=>setLastName(e.target.value)}
          placeholder='Your Last Name' name='name'/>
        </div>
      </div>
      <div className='flex flex-col items-start'>
        <label className='font-bold text-2xl mb-2 text-gray-600'>TelPhone Number</label>
        <input
           className='border p-2 rounded-md mt-1 mb-2 w-1/2'
          value={PhoneNumber}
          type='number'
          onChange={(e)=>setPhoneNumber(e.target.value)}
          placeholder='your Phone Number'/>
      </div>

      <div className='flex flex-col items-start'>
        <label className='font-bold text-2xl mb-2 text-gray-600'>Email</label>
        <input
           className='border p-2 rounded-md mt-1 mb-2 w-1/2'
          value={Email}
          type='email'
          onChange={(e)=>setEmail(e.target.value)}
          placeholder='you Email' name='email'/>
      </div>
      <div className='flex flex-col items-start'>
        <label className='font-bold text-2xl mb-2 text-gray-600'>Message</label>
        <textarea
           className='border p-4 rounded-md mt-1 mb-2 w-full'
          value={Message}
          name='message'
          onChange={(e)=>setMessage(e.target.value)}
          placeholder='Your Message'/>
      </div>
      <div className='w-fit mt-2 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>
       <input type='submit' value={'send'}/>
      </div>
        </form>
      
      
      </div>

      <div>
        <div className='mr-20 mt-10'>
        <h1 className='text-start font-semibold mb-3  mt-1 text-xl'>Our Contact</h1>
        <h1 className='text-start font-bold text-2xl mb-2 text-gray-600'>Email Us on : mathieurflamini@gmail.com</h1>
        </div>
        <div>
          <h1 className='text-start font-semibold mb-3  mt-1 text-xl'>Phone Number</h1>
          <h1 className='text-start font-bold text-2xl mb-2 text-gray-600'>Contact us on This Number : +250780456319</h1>
        </div>

        <div>
          <h1 className='text-start font-semibold mb-3  mt-1 text-xl'>Location</h1>
          <h1 className='text-start font-bold text-2xl mb-2 text-gray-600'>Our Location </h1>
        </div>
       
      </div>

      </div>
      <Footer/>
      
    </div>
  )
}

export default ContactUs
