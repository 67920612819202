import { createSlice } from "@reduxjs/toolkit";

const PosterSlice = createSlice({
    name:'Poster',
    initialState:{
      Posters:[],
    },
    reducers:{
        Poster:(state, action)=>{
            state.Posters=action.payload
        },

        EmptyingPoster : (state)=>{
            state.Posters=[];
        }
    }
});
export const {Poster, EmptyingPoster}=PosterSlice.actions;

export const DisplayPoster = (state) => state.Poster.Posters;

export default PosterSlice.reducer;