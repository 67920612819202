import React, { useEffect } from 'react'
import ActivityContainer from './ActivityContainer'
import Footer from './Footer'

function Tours() {
    useEffect(()=>{
        window.scrollTo(0,0)
      },[]);
  return (
    <div className='flex flex-col items-center bg-blue-200'>
        <div className='pt-10 pb-10'>
            <h1 className='text-4xl font-extralight text-center mt-2 mb-6'>Plan your</h1>
            <h1  className='text-8xl font-bold text-center text-ellipsis mt-2 mb-6'>Perfect Tours</h1>
        </div>
        <div className='flex flex-1 justify-between pt-4 flex-wrap w-full px-5 pr-5'>
        <ActivityContainer
        ButtonText="Book"
        />
        </div>
       <Footer/>
        
    </div>
  )
}

export default Tours