import React from 'react'
import NavBar from './NavBar'
import WorkingPanel from './WorkingPanel'

function Administrator() {
  return (
    <div className='flex flex-col flex-1 h-[32rem]'>
        <div className='border p-2 mt-1 mb-3'>
        <h1>Admin panel</h1>
        </div>
       
        <div className='flex mt-1 p-2 h-full'>
            <div className='border p-8 mx-1 mr-1/2 h-full overflow-auto  min-w-min'>
            <NavBar/>
            </div>
        <div className='flex-1 border mx-1 overflow-y-auto'>
        <WorkingPanel/> 
         </div>
               
        </div>
    </div>
  )
}

export default Administrator
