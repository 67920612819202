import React, { useEffect } from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import SlideImg1 from '../Images/HomePage.jpeg';
import Four from '../Images/four.jpeg';
import Six from '../Images/six.jpeg';
import Footer from './Footer';
import { Avatar } from '@mui/material';
import { Dbconn } from '../ConnectionToDatabase/Connect';
import { useDispatch, useSelector } from 'react-redux';
import { SelectActivityName } from '../../features/ActivityNameSlice';
import { AllUsers, SelectActivity } from '../../features/ActivitySlice';

const spanStyle = {
  padding: '20px',
  color: '#FFFFFF',
  margintTop:'20px'

}

const divStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundSize: 'fit',
  height:'400px',
  width:'100%'
 

}

const slideImages = [
  {
    url:SlideImg1,
    caption: 'THE BEST WAY TO SEE AFRICA!'
  },
  {
    url: Four,
    caption: 'RUBAVU BEST AND BERY TOURISTIC DISRTICT'
  },
  {
    url: Six,
    caption: 'OUR DIFFERENT QUATER VIEW'
  },
];

function AboutUs() {
  const dispatch = useDispatch();
  const ActivityName = useSelector(SelectActivityName);
  const GetUser= useSelector(SelectActivity);
  useEffect(()=>{
    window.scrollTo(0,0)
  },[]);
    let Dat=[];
  useEffect(()=>{(async()=>{
    if(ActivityName==='AboutUs'){
      await Dbconn.collection('DataSecurity').doc('UsersCridentials').collection('Users').onSnapshot(
        (onSnapshot)=>{
          Dat=[];
          if(!onSnapshot.empty){
            onSnapshot.forEach((doc)=>{
              let value={
                Names:doc.data().User.FirstName + "  " + doc.data().User.LastName,
                UserProfile:doc.data().User.Profile,
                UserTitle:doc.data().User.Title,

              }
              Dat.push(value)
            })
            dispatch(AllUsers(Dat))
          }
          else{
  
          }
          
        }
      )
    }
    
  })
();
},[ActivityName]);
  return (
    <div className='laptop:mx-16 laptop:mr-16 laptop:w-auto small:w-full Phone:w-full tablet:w-full'>
      <div className='w-full border border-solid mt-1'>
        <div>
        <Slide>
         {slideImages.map((slideImage, index)=> (
            <div key={index}>
              <div style={{ ...divStyle, 'backgroundImage': `url(${slideImage.url})` }}>
                <span style={{...spanStyle, fontSize:'40px', fontWeight:'bold', margintTop:'30px',textAlign:'center'}}>{slideImage.caption}</span>
              </div>
            </div>
          ))}
        </Slide>

        </div>

      </div>
      <div className=' flex fle-row justify-between'>
        <div className='fex-1 mt-50'>
          <div>
         <h1 className='text-start mx-4 text-3xl mt-5 mb-5 font-extrabold text-green-700'>About</h1>
         <h1 className='text-start mx-4 text-3xl mt-5 mb-5 font-extrabold text-green-700'>Go Gisenyi Tours</h1>

          </div>

          {/* user profile */}
          <div className='flex flex-row items-center flex-wrap self-center'>
            {GetUser.length!==0?
            GetUser.map((data, i)=>(
              <div key={i} className='flex flex-col w-fit mx-4 mr-4 border pr-4 px-4 pt-2 pb-2 shadow-lg'>
              <Avatar src={data.UserProfile} sx={{width:210, height:210, alignSelf:'center'}}/>
              <div className='self-center mt-4 mb-2'>
                <h1 className='font-semibold text-center text-gray-700 text-xl mt-2 mb-2'>{data.Names}</h1>
                <h1 className='font-bold text-center mt-2 mb-2'>{data.UserTitle}</h1>
              </div>
              </div>
            ))
            :null}            
          </div>
        <p className='fonf-bold mt-2 mb-4 text-gray-500 font-thin text-xl font-sans text-start mx-4 w-3/4'>The director of Go Gisenyi Tours is an experienced tour operator.</p>
      <p className='fonf-bold mt-2 mb-4 text-gray-500 font-thin text-xl font-sans text-start mx-4 w-3/4'>With more than 5 years of experience, Francis has guided numerous easy as well as challenging trips on the Congo Nile trail, guiding the international tourists, both when only camping was possible, as well as now where guesthouses and other accommodations are available on the shore along the trail.</p>
      <p className='fonf-bold mt-2 mb-4 text-gray-500 font-thin text-xl font-sans text-start mx-4 w-3/4'>Francis holds bachelor degrees in travel and tourism management from  (RTUC) and different certificates of achievement credited British canoe union and by RDB, the institution in charge of tourism in Rwanda.</p>
      <p className='fonf-bold mt-2 mb-4 text-gray-500 font-thin text-xl font-sans text-start mx-4'>He really likes this job, beyond having the expertise and capacity to organize the best hiking, biking and wild life tour packages.</p>
      <p className='fonf-bold mt-2 mb-4 text-gray-500 font-thin text-xl font-sans text-start mx-4 w-3/4'>Under the experienced guidance of Go Gisenyi Tours, discover the natural jewels and cultural heritage across Rwanda. The tour operator organizes special excursions to coffee and tea plantations, thrilling bike adventures, and city tours. Engage in a traditional fishing night and learn about life of the local fishers cooperative, or see the dancing pots of the indigenous Batwa tribe. </p>
        </div>
        <div className='flex-1'>
        </div>
      </div>
      <Footer/>
      </div>
  )
}

export default AboutUs
