import React, { useEffect} from 'react';
import { Dbconn } from '../ConnectionToDatabase/Connect';
import ActivityContainer from './ActivityContainer';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import SlideImg1 from '../Images/HomePage.jpeg';
import Four from '../Images/four.jpeg';
import Six from '../Images/six.jpeg';
import Footer from './Footer';
import { useDispatch, useSelector } from 'react-redux';
import { AllPosts } from '../../features/PostSlice';
import { SelectBoardImages } from '../../features/ActivitySlice';


const spanStyle = {
  padding: '20px',
  color: '#FFFFFF',
  margintTop:'20px',

}

const divStyle = {
  display: 'flex',
  // alignItems: 'center',
  // justifyContent: 'center',
  // backgroundSize: 'cover',
  height:'700px',
  width:'100%',
 
 

}

const divStyle2 = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundSize: 'cover',
  height:'700px',
  width:'100%',
 

}

const slideImages = [
  {
    url:SlideImg1,
    caption: 'THE BEST WAY TO SEE AFRICA!'
  },
  {
    url: Four,
    caption: 'RUBAVU BEST AND VERY TOURISTIC DISRTICT'
  },
  {
    url: Six,
    caption: 'DIFFERENT PLACE YOU CAN VISIT'
  },
];

function GisenyiTourHome() {

  useEffect(()=>{
    window.scrollTo(0,0)
  },[]);

  const AllBoardImages = useSelector(SelectBoardImages);
  // console.log(AllBoardImages)
//   useEffect(()=>{(
//     async()=>{
// await Dbconn.collection('DataSecurity').doc('UsersCridentials').collection('AllGoGisenyiToursPosts').onSnapshot(
//   (onSnapshot)=>{
//     if(onSnapshot.empty){
      

//     }
//     else if(!onSnapshot.empty){

//     }
//   }
// )
//   })
// ();
// },[])


// getting all Posts from store
const dispatch = useDispatch();
let AllPOsts=[];
useEffect(()=>{(async()=>{
await Dbconn.collection('DataSecurity').doc('UserCridentials').collection('AllGoGisenyiToursPosts').where('Post.Available','==','Available').onSnapshot(
  (snapshot)=>{
    AllPOsts=[];
    if(!snapshot.empty){
      snapshot.forEach((doc)=>{
        let Post={
          PostId:doc.id,
          PostThumb:doc.data().Post.PostThumb,
          PostTitle:doc.data().Post.PostTitle,
          PostDescription:doc.data().Post.PostDescription,
          
        }
        AllPOsts.push(Post)
      })
      if(AllPOsts.length>=1){
        dispatch(AllPosts(AllPOsts))
      }

    }
    else if(snapshot.empty){

    }
    else{

    }
  }
)
})
();
},[AllPOsts])
  return (
    <div className='laptop:mx-2 laptop:mr-2 small:w-full Phone:w-full tablet:w-auto laptop:w-auto'>
      {/* displayer */}
      <div className='w-full border border-solid mt-1'>
        <div>
        <Slide>
         {AllBoardImages!=[] && AllBoardImages!=undefined && AllBoardImages!=null && AllBoardImages!=''?
          AllBoardImages.map((slideImage, index)=> (
            <div key={index} className=' w-screen'>
              <div style={{ ...divStyle2, 'backgroundImage': `url(${slideImage.data})` }}>
                <span style={{...spanStyle, fontSize:'40px', fontWeight:'bold', margintTop:'30px', textAlign:'center'}}>{slideImage.title}</span>
              </div>
            </div>
          ))
         :    
         slideImages.map((slideImage, index)=> (
            <div key={index}>
              <div style={{ ...divStyle2, 'backgroundImage': `url(${slideImage.url})` }}>
                <span style={{...spanStyle, fontSize:'40px', fontWeight:'bold', margintTop:'30px', textAlign:'center'}}>{slideImage.caption}</span>
              </div>
            </div>
          ))}
        </Slide>

        </div>

        <div>
          <h1 className='text-5xl font-bold text-center text-green-800 mx-100 p-10 mr-100 w-auto'>Explorer Our Go Gisenyi Tours Circuits</h1>
        </div>

      </div>
    <div className='flex flex-1 justify-between pt-4 flex-wrap w-full'>
    <ActivityContainer
    // title="1 DAY COFFEE ROASTING EXPERIENCE"
    // image={One}
    // Description="Arabica and Maraba coffee of Rwanda’s biggest export, the bourbon beans are grown with formers on volcanic soils at 1450m and 1 800m.This widely sought after bean has a good body and classic lean taste. Join us on tour from crop to cup to experience each step of coffee making process up to the end…"
    ButtonText="Read More"
    />
     {/* <ActivityContainer
    title="4 DAYS HIKING ON CONGO NILE TRAILS"
    image={Two}
    Description="The Congo Nile Trail experience on Lake Kivu shores covers a range of attractions, of which the biggest on is the watershed separating two greatest African basins (Congo and Nile). We hike and bike through the impressive landscapes which provide a habitat to a great variety of plants and birds. The itinerary: Gisenyi-Kibuye 4 days hike,…"
    ButtonText="Read More"
    />
     <ActivityContainer
    title="BIKING THE CONGO NILE TRAIL"
    image={Three}
    Description="Biking the Congo Nile Trail  on guided tour We organize the Cycling tour  for you from Gisenyi to Kibuye in two or three challenging days stopping overnight at the beautiful Kinunu guesthouse. Day1: Gisenyi to Kinunu The day starts in morning at 8am to our office heading to the southern part of lake and enjoy…"
    ButtonText="Read More"
    />

    <ActivityContainer
   title="POTTERING EXPERIENCE & THE DANCING POTS"
   image={Four}
    Description="The dancing pots, the pottery projects known as Dancing pots of the indigenous Batwa tribe, was the first certified fair Trade business in Rwanda. Today about 33,000 Batwa remain in Rwanda represent 0,4 percent of the population . They are particularly renowned for their skills as potters, dancers and musicians. The dancing pot is a…"
    ButtonText="Read More"
    />

<ActivityContainer
   title="TRADITIONAL NIGHT FISHING"
   image={Five}
    Description="Go for a fishing experience, an eco tourism activity and opportunity to enjoy a traditional night fishing experience, sunset with a local fishermen’s cooperative. Look for them around the beer brewery in the Gisenyi bay, 7km from Serena Hotel. 50$PP"
    ButtonText="Read More"
    />

<ActivityContainer
   title="BIKING THE CONGO NILE TRAIL"
   image={Six}
    Description="The Pfunda tea Tours is a wonderful agriculture tourism attraction which benefits the local community. It is 11 km from Gisenyi town and easily accessible by car. Two partners are involved: the Cooperative, which are a local cooperative, and the Pfunda tea factory. They plan to develop it into tea tourism from the harvesting experience…"
    ButtonText="Read More"
    />

<ActivityContainer
   title="1 DAY TEA TOUR"
   image={Seven}
    Description="The dancing pots, the pottery projects known as Dancing pots of the indigenous Batwa tribe, was the first certified fair Trade business in Rwanda. Today about 33,000 Batwa remain in Rwanda represent 0,4 percent of the population . They are particularly renowned for their skills as potters, dancers and musicians. The dancing pot is a…"
    ButtonText="Read More"
    />

<ActivityContainer
    title="7 DAYS IN RWANDA"
    image={Eight}
    Description="Itinerary Day 1: Pick up Pick up at Kigali airport and sightseeing tour through the city of Kigali include Gisozi genocide museum Day 2: Transfer to Musanze  Drive to musanze for Golden Monkey trekking and visit culture canters Day 3: Gisenyi tours  Transfer to Gisenyi (City tour and boat ride on Lake Kivu and the…"
    ButtonText="Read More"
    />

<ActivityContainer
    title="ABOUT US"
    image={Nine}
    Description="Our titles and passion for tourism along our professional history."
    ButtonText="Read More"
    /> */}
    </div>


<div className='mb-5'>
  <Footer/>
</div>
    </div>
  )
}

export default GisenyiTourHome
