import React, { useEffect, useState } from 'react';
import './App.css';
import {
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import Header from './app/Components/Header';
import Home from './app/Components/Home';
import { useDispatch, useSelector } from 'react-redux';
import { Selectuser } from './features/UserSlice';
import GisenyiTourHome from './app/Components/Go-Gisenyi-Tours-Home';
import AboutUs from './app/Components/AboutUs';
import ContactUs from './app/Components/ContactUs';
import Blog from './app/Components/Blog';
import Event from './app/Components/Event';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { SelectActivityName } from './features/ActivityNameSlice';
import Administrator from './app/Components/Administrator';
import Tours from './app/Components/Tours';
import { Dbconn } from './app/ConnectionToDatabase/Connect';
import { SelectBoardImages, setAllBoardImages } from './features/ActivitySlice';

function App() {
  const navigate = useNavigate();
  const User =useSelector(Selectuser);

  const dispatch = useDispatch();
  const[WhoUser, setWhoUser]=useState("");
  const SelectActivity=useSelector(SelectActivityName);
  // check if user is available
  useEffect(()=>{
    if(User!=null && User.User.Category==='Administrator'){
      navigate('/Admin');
      

    }
    else{
      navigate('/')
    }
  },[User]);

  const AllBoard = useSelector(SelectBoardImages);
  useEffect(()=>{
    (async()=>{
await Dbconn.collection('HomBOardImagesSlider').get().then(
  (querysnapshot)=>{
    if(!querysnapshot.empty){
      let All=[];
     querysnapshot.forEach((doc)=>{
      let value={
        id:doc.id,
        data:doc.data().Image,
        title:doc.data().title!=undefined && doc.data().title!=null?doc.data().title:'',
      }
      console.log(value);
      All.push(value)
     })
     if(All.length!=0){
      dispatch(setAllBoardImages(All))
     }
    }
    else{
      return;
    }
  }
)
    })
    ();
  },[])
  useEffect(()=>{
   if(SelectActivity==='AboutUs'){
    navigate('/AboutUs');
  
   }
   else if(SelectActivity==='Blog'){
    navigate('/Blog');
   }
   else if(SelectActivity==='Event'){
    navigate('/Event')
   }
   else if(SelectActivity==='Tours'){
    navigate('/Tours')
   }
   else if(SelectActivity==='Users' || SelectActivity==='Add-Users' || SelectActivity==='All-Posts' || SelectActivity=='Post-a-Journey'){
    navigate('/Admin')
   }
   else if(SelectActivity==='/'){
    navigate('/');
   }
   else{
    return;
   }
  },[SelectActivity]);




  return (
    < LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="App">
      <Header/>
        <Routes> 
          <Route path='/' element={<GisenyiTourHome/>}/>
          <Route path='/Home' element={<Home/>}/>
          <Route path='/Admin' element={<Administrator/>}/>
          <Route path='/AboutUs' element={<AboutUs/>}/>
          <Route path='/ContactUs' element={<ContactUs/>}/>
          <Route path='/Blog'  element={<Blog/>}/>
          <Route path='/Event' element={<Event/>}/>
          <Route path='/Tours' element={<Tours/>}/>
        </Routes>
      </div>
    
    </LocalizationProvider>
    
  );
}

export default App;
