import { createSlice } from "@reduxjs/toolkit";

const PostSlice = createSlice({
    name:'Post',
    initialState:{
      Posts:[],
    },
    reducers:{
        AllPosts:(state, action)=>{
            state.Posts=action.payload
        },

        EmptyingPosts : (state)=>{
            state.Posts=[];
        }
    }
});
export const {AllPosts, EmptyingPosts}=PostSlice.actions;

export const RetreiveAllPosts = (state) => state.Post.Posts;

export default PostSlice.reducer;