import React from 'react';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useNavigate } from 'react-router-dom';
import advisor from "../Images/tripadvisor.png"

function Link() {
  const navigate = useNavigate();
  return (
    <div className='flex flex-row justify-between bottom-1/2 top-80 right-0 left-full sticky z-50 p-5 w-fit outline-none small:flex-wrap'>
       <div
      onClick={()=>{
        window.open('https://www.tripadvisor.com/Attraction_Review-g317074-d10557048-Reviews-Go_Gisenyi_Tours-Gisenyi_Western_Province.html','_blank')
      }}
      className='flex flex-col items-center mt-2 m-4'>
        <h1>Tripadvisor</h1>
      <img src={advisor} className='cursor-pointer w-10 h-10 bg-white rounded-md c'/>

      </div>
     
      <div
      onClick={()=>{
        window.open('https://web.facebook.com/Gogisenyitours/','_blank')
      }}
      className='flex flex-col items-center mt-2 m-4'>
        <h1>Facebook</h1>
      <FacebookIcon sx={{color:'blue', fontSize:40, cursor:'pointer'}}/>

      </div>
    <div 
    onClick={()=>{
      window.open('https://www.instagram.com/gogisenyi_tours_ltd/','_blank')
    }}
    className='flex flex-col items-center mt-2 m-4'>
      <h1>Instagram</h1>
    <InstagramIcon 
    sx={{outlineColor:'white',fontSize:40, cursor:'pointer'}}
    />
    </div>
    <div 
    onClick={()=>{
      window.open('https://www.youtube.com/watch?v=KYml1mPyx1I','_blank')
    }}
    className='flex flex-col items-center mt-2 m-4'>
      <h1>YouTube</h1>
    <YouTubeIcon sx={{color:'red', fontSize:40, cursor:'pointer'}}/>
    </div>
    
      <div
      onClick={()=>{
        window.open('https://twitter.com/GogisenyiTours','_blank')
      }}
      className='flex flex-col items-center mt-2 m-4'>
      <h1>Twitter</h1>
        <TwitterIcon  sx={{color:'blue', fontSize:40, cursor:'pointer'}}/>
      </div>
      <div 
      onClick={()=>{
        window.open('https://www.linkedin.com/in/go-gisenyi-tours-ltd-1245a6160/','_blank')
      }}
      className='flex flex-col items-center mt-2 m-4'>
      <h1>Linked In</h1>
        <LinkedInIcon  sx={{color:'blue', fontSize:40, cursor:'pointer'}}/>
      </div>

   
    
    
    
  </div>
  )
}

export default Link