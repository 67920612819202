import { Button } from '@mui/base'
import { DatePicker } from '@mui/x-date-pickers'
import React, { useEffect, useState } from 'react'
import emailjs from 'emailjs-com';
import Footer from './Footer'
import { useSelector } from 'react-redux'
import Moment from 'moment';
import OtherModal from 'react-modal';
import { DisplayPoster } from '../../features/PostDisplay'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from "react-spinners/CircleLoader";

function Event() {
  const [Name, setName]=useState('');
  const[FromDate, setFromDate]=useState('');
  const[ToDate, setToDate]=useState('');
  const[Email, setEmail]=useState('');
  const[VisitDate,setVisitDate]=useState('');
  const[Title, setTitle]=useState('');
  const[loadingModal, setloadingModal]=useState(false);
  useEffect(()=>{
    window.scrollTo(0,0)
  },[]);
  useEffect(()=>{
   if(FromDate!=='' && ToDate!==''){
    setVisitDate("From :  " + FromDate   +"  " +"=>" + "  "  + "To : " + ToDate);
    setTitle(GetPoster.data.PostTitle);
   }
      },[FromDate, ToDate, VisitDate]);

      const BookForaJourney = async(e)=>{
        setloadingModal(!loadingModal)
        e.preventDefault();
        emailjs.sendForm('service_ue9l4vt', 'template_ovgc7ly', e.target, 'Kf6WHvdelAA4yrlf2')
       .then((result) => {
           setEmail('');
           setFromDate('');
           setToDate('');
           setName('');
           setloadingModal(false);
           return toast.success(`${result.text} : Thank you for Booking, Soon we will contact you.`);
           
       }, (error) => {
        setloadingModal(false);
           toast.error(error.text);
       });
      }
  const GetPoster = useSelector(DisplayPoster);
  console.log(GetPoster)
  return (
    <div className='flex flex-1 flex-col'>
      <ToastContainer position="top-center" limit={1} />
      <div>
      <img src={GetPoster.data.PostThumb} className='w-full h-96 object-cover' />
      <h1 className='text-center font-bold tex-3xl mt-2 mb-5'>{GetPoster.data.PostTitle}</h1>
      <p className='laptop:mr-20 laptop:mx-10 laptop:text-start w-auto mt-2 mb-4 small:mr-5 text-lg small:mx-4 Phone:mr-5 Phone:mx-4 small:text-justify Phone:text-justify tablet:text-justify'>{GetPoster.data.PostDescription}</p>
      </div>
      <form onSubmit={BookForaJourney}>
      <div className='border flex  flex-col laptop:w-96 small:w-fit Phone:w-fit tablet:w-fit mb-2 shadow-lg mx-2'>
        <h1 className='text-center mx-4 text-xl mt-2 mb-2 font-extrabold text-green-700'>Select Date to visit</h1>
        <div className='flex flex-row  justify-between p-4'>
          <div  className='mr-2'>
            <h1 className='text-start mb-2 font-semibold text-green-700'>From</h1>
          <DatePicker
          onChange={(date)=>setFromDate(Moment(new Date(date)).format('YYYY:MM:DD'))}
          />
          </div>
          <div className='mx-2'>
            <h1 className='text-start mb-2 font-semibold text-green-700'>To</h1>
          <DatePicker
          onChange={(date1)=>setToDate(Moment(new Date(date1)).format('YYYY:MM:DD'))}
          />
          </div>
         <div className=' hidden'>
          <input
          onChange={(e)=>setVisitDate(e.target.value)}
          value={VisitDate}
          name='date'/>
         </div>
         
        </div>
        <div className='flex flex-col mt-4 mb-4 self-center w-fit'>
        <input
          name='title'
          value={Title}
          onChange={(e)=>setTitle(e.target.value)}
          className='border px-10 pr-10 pt-2 pb-2 rounded-md mt-1 mb-2 w-fit hidden' placeholder='Your Names' required/>
          <input
          name='name'
          value={Name}
          onChange={(e)=>setName(e.target.value)}
          className='border px-10 pr-10 pt-2 pb-2 rounded-md mt-1 mb-2 w-fit outline-none' placeholder='Your Names' required/>
          <input
          name='email'
          value={Email}
          onChange={(e)=>setEmail(e.target.value)}
          className='border px-10 pr-10 pt-2 pb-2 rounded-md mt-1 mb-2 w-fit outline-none' placeholder='Your email' type='email' required/>
        </div>
        <div className='self-center w-fit mt-2 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded  cursor-pointer'>
          <input  className='cursor-pointer' disabled={Name=='' || Email=='' || FromDate=='' || ToDate==''?true:false} type='submit' value={'Book'}/>
        </div>
      </div>

      </form>
    
      <Footer/>

      <OtherModal
  className=' bottom-1/2 top-64 right-5 left-1/2 sticky z-50 p-5 w-fit h-fit pr-4 px-4'

     header={'Edit'}
     isOpen={loadingModal}
     appElement={document.getElementById('app')}
     ariaHideApp={false}>
<div className='flex  flex-1 self-center items-center justify-center'>
<ClipLoader
color='cyan'
size={100}
/>
</div>
 </OtherModal>
    </div>
  )
}

export default Event
