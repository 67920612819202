import { createSlice } from "@reduxjs/toolkit";

const ActivityNameSlice = createSlice({

    name:'Activityname',
    initialState:{
        Name:''
    },

    reducers:{

        ActivityNames : (state, action) =>{
            state.Name = action.payload
        },


    }
});

export const {ActivityNames} = ActivityNameSlice.actions;

export const SelectActivityName = (state) => state.Activityname.Name;

export default ActivityNameSlice.reducer;