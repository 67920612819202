import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import OtherModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { ActivityNames, SelectActivityName } from '../../features/ActivityNameSlice';
import { RetreiveAllPosts } from '../../features/PostSlice';
import { toast, ToastContainer} from 'react-toastify';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from "react-spinners/CircleLoader";

import {
  useNavigate,
  redirect
} from "react-router-dom";
import { DisplayPoster, Poster } from '../../features/PostDisplay';
import { Dbconn, Storage } from '../ConnectionToDatabase/Connect';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';

function ActivityContainer({ButtonText}) {

  const dispatch=useDispatch();
  const navigate = useNavigate();

  const GetAllPOst = useSelector(RetreiveAllPosts);
  const ActivityName=useSelector(SelectActivityName);
  const GetPoster = useSelector(DisplayPoster);
  const[EditingModal, setEditingModal]=useState(false);

  const[loadingModal, setloadingModal]=useState(false);
  const[PDescription, setPDescription]=useState('');
  const[PTitle, setPTitle]=useState('');
  const[PThumb, setPThumb]=useState('');
  const[Docid, setDocid]=useState('');
  const[imagefile, setimagefile]=useState();
  const[File, setFile]=useState(null);
  const[PostThumb, setPostThumb]=useState(null);

  useEffect(()=>{
    if(GetPoster.length!=0){
      setPDescription(GetPoster.data.PostDescription);
      setPTitle(GetPoster.data.PostTitle);
      setPThumb(GetPoster.data.PostThumb);
      setDocid(GetPoster.data.PostId);
    }

  },[GetPoster]);

 
  const GetImage = (event)=>{

    if(event.target.files && event.target.files[0]){
      setimagefile(URL.createObjectURL(event.target.files[0]))
      setFile(event.target.files[0])
    }
    else{
      setFile(null);
      setimagefile();
    }
         }
    



  const [percent, setPercent] = useState(0);
  const UploadImageToStorage = async()=>{
   if (!File) {
    return toast.error(
     "Image File is Missing, please Choose Image"
   )
 }
else{
const storageRef = ref(Storage,`/files/${Docid}}`)
const uploadTask = uploadBytesResumable(storageRef, File);

uploadTask.on(
   "state_changed",
   (snapshot) => {
       const percent = Math.round(
           (snapshot.bytesTransferred / snapshot.totalBytes) * 100
       );

       // update progress
       setPercent(percent);
   },
   (err) => console.log(err),
   () => {
       // download url
       getDownloadURL(uploadTask.snapshot.ref).then((url) => {
           console.log(url);
           setPostThumb(url);
          
       }).then(
         toast.success("Image Uplaoded Succesfuly"),
         setloadingModal(false)
       )
   }
); 



}

  }

  const UpdatePost = async()=>{
    let PostP='';
    if(PostThumb==null){
      PostP=PThumb;
    }
    else{
      PostP=PostThumb;
    }
    await Dbconn.collection('DataSecurity').doc('UserCridentials').collection('AllGoGisenyiToursPosts').doc(GetPoster.data.PostId).update({
     "Post.PostDescription":PDescription,
     "Post.PostTitle":PTitle,
     "Post.PostThumb":PostP,
     "Post.Available":"Available"
    }).then(
      ()=>{
        setEditingModal(false);
        setFile(null);
        setloadingModal(false);
        return toast.success("Post has been updated")
      }
    )

  
  }

  const DeletePost = async()=>{
    await Dbconn.collection('DataSecurity').doc('UserCridentials').collection('AllGoGisenyiToursPosts').doc(GetPoster.data.PostId).update({
      "Post.Available":"UnAvailable"
    }).then(
      ()=>{
        setEditingModal(false);
        return toast.success("Post has been deleted")
      }
    )

  }

  return (
    <div>


    
    <div className='flex flex-row flex-wrap justify-around laptop:mx-10 laptop:mr-10 small:w-full Phone:w-full tablet:w-auto laptop:w-auto'>
      {GetAllPOst.length>=1?
      GetAllPOst.map((data, i)=>(
        <div  key={i} className='p-4 border mb-3 mx-1 mr-1 shadow-lg bg-transparent flex justify-between flex-col Phone:w-full target:w-full laptop:w-96 bg-white'>
        <img src={data.PostThumb} className=' h-72'/>
        <h1 className='text-center font-bold tex-xl mt-1 mb-3'>{data.PostTitle}</h1>
        <h2 className="tracking-wide text-gray-500 md:text-lg dark:text-gray-400 text-xl">{data.PostDescription.length>100?`${data.PostDescription.substring(0, 100)}.........`:data.PostDescription}</h2>
        <div className='mt-4 self-center'>
        <Button 
        onClick={()=>{
          if(ActivityName==='All-Posts'){
            setEditingModal(!EditingModal);
          } 
          else{
            dispatch(ActivityNames('Event')) 
          }
         dispatch(Poster({data}));
        }}
         variant='contained'>{ButtonText}</Button>
        </div>
       
      </div>
      ))
    :
     null} 

{/* editing content  */}
     <OtherModal
     className=' bottom-1/2 top-40 right-5 left-1/2 sticky z-50 p-5 bg-gray-400  w-full pr-4 px-4'
     onRequestClose={()=>{setEditingModal(!EditingModal); setFile(null)}}
     header={'Edit'}
     isOpen={EditingModal}
     appElement={document.getElementById('app')}
     ariaHideApp={false}
     >
      <div className='flex self-center mt-2 mb-2 items-center justify-center'>
          <Button
          onClick={()=>{
            setEditingModal(false)
            setFile(null);
          }}
          sx={{fontsize:18, fontWeight:'bold', color:'red'}}
          >Close</Button>
        </div>
      <div className='flex flex-row'>
      <div  className='p-4 border mb-3 mx-1 mr-1 shadow-lg  w-96 flex justify-between flex-col Phone:w-full tablet:w-full laptop:w-96'>
       {GetPoster.length!=0? <img src={imagefile==undefined?PThumb:imagefile} className=' h-72'/>:null}
       
        <div className='mt-4'>
       
          <div className='w-fit mt-2 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>
        <input 
        onChange={GetImage}
        accept='image/*'
        type='file'/>
        </div>
          <div className='flex flex-row items-center justify-between'>
          {File!=null?
         <div  className='mt-4 mb-4 text-white font-bold py-2 px-4 rounded  w-fit self-center cursor-pointer'>
         <Button 
         sx={{fontStyle:'normal'}}
         onClick={()=>{
          setloadingModal(!loadingModal);
          UploadImageToStorage();
         }}
          variant='contained'>Change</Button>
         </div>:null}
          {File!=null && PostThumb!=null ?
          <CheckCircleIcon 
          onClick
          sx={{color:'green'}}/>:null}
          </div>
       
       
        </div>
       
      </div>
      <div className='flex w-full flex-col mr-5'>
      <input
        value={PTitle}
        onChange={(e)=>setPTitle(e.target.value)}
        placeholder='Post Title' className='text-center font-bold tex-xl mt-1 mb-3 w-full outline-none p-4'/>
        
      <textarea
         className='p-10 text-gray-600 w-full border  h-48 mt-4 text-lg outline-none'
        value={PDescription}
        onChange={(e)=>setPDescription(e.target.value)}
        placeholder='Description'/>
        
        <div className='flex flex-row self-center'>
        <div  className='mt-4 mb-4 text-white font-bold py-2 px-4 rounded  w-fit self-center cursor-pointer'>
        <Button 
        sx={{fontStyle:'normal'}}
        onClick={()=>{
          if(PDescription=='' || PTitle==''){
           toast.error("You Can Not uplaod Empty on Null value to the store")
          } 
          else if(PDescription==GetPoster.data.PostDescription && PostThumb==null && PTitle==GetPoster.data.PostTitle){
            toast.error("nothing to update record are the same")
          }
          else{
            setloadingModal(!loadingModal);
            UpdatePost();
          }
        }}
         variant='contained'>Save Edit</Button>
        </div>
        <div  className='mt-4 mb-4 text-white font-bold py-2 px-4 rounded  w-fit self-center cursor-pointer'>
        <Button 
        onClick={()=>{
          let Ok=window.confirm("You Are About to Delete this Post\n\nDo you want to Continue?")
          if(Ok){
            DeletePost();
          }
          else{
            alert("the Process has been declined")
          }
        }}
        sx={{fontStyle:'normal', backgroundColor:'red'}}
        // onClick={()=>{
        //   if(ActivityName==='All-Posts'){
        //     setEditingModal(!EditingModal);
        //   } 
        //   else{
        //     dispatch(ActivityNames('Event')) 
        //   }
        //  dispatch(Poster({data}));
        // }}
         variant='contained'>Delete</Button>
        </div>

        </div>
       
      </div>
     
      </div>
  
       

     </OtherModal>
 {/* Loading Modal */}
 <OtherModal
  className=' bottom-1/2 top-64 right-5 left-1/2 sticky z-50 p-5 w-fit h-fit pr-4 px-4'

     header={'Edit'}
     isOpen={loadingModal}
     appElement={document.getElementById('app')}
     ariaHideApp={false}>
<div className='flex  flex-1 self-center items-center justify-center'>
<ClipLoader
color='cyan'
size={100}
/>
</div>
 </OtherModal>
    </div>
    <ToastContainer position="top-center" limit={1} />
    </div>
  )
  
}

export default ActivityContainer
