import React, { useEffect, useState } from 'react'
import {Alert, Avatar, capitalize, Card, CardActionArea, CardActions, CardContent, CardMedia, Checkbox, FormControl, ImageList, InputLabel, MenuItem, Rating, Select, TablePagination, Typography} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/base';
import { Selectuser } from '../../features/UserSlice';
import ActivityContainer from './ActivityContainer';
import { SelectActivity, SelectBoardImages, SelectUsers, setAllBoardImages } from '../../features/ActivitySlice';
import ActivityNameSlice, { ActivityNames, SelectActivityName } from '../../features/ActivityNameSlice';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import PhoneIcon from '@mui/icons-material/Phone';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer";
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { v4 as uuidv4 } from 'uuid';
import { ActivityName } from '../../features/ActivityNameSlice';
import OtherModal from "react-modal";
import { Dbconn } from '../ConnectionToDatabase/Connect';
import { Storage } from '../ConnectionToDatabase/Connect';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import {Document, page} from "react-pdf";
import { Box } from '@mui/system';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import { toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RetreiveAllPosts } from '../../features/PostSlice';
import { DisplayPoster } from '../../features/PostDisplay';
import ClipLoader from "react-spinners/CircleLoader";

function Activity() {

    const User = useSelector(Selectuser);
    const Activity = useSelector(SelectActivity);
    const ActivityName=useSelector(SelectActivityName);
    const[UserRight, setUserRight]=useState('');
    const GetAllPost = useSelector(RetreiveAllPosts);
    const GetPoster = useSelector(DisplayPoster);
    const AllBoardImages= useSelector(SelectBoardImages);
    console.log(AllBoardImages);
    
    // variables to hold post nformation
    const[PostTitle, setPostTitle]=useState('');
    const[PostImage, setPostImage]=useState(null);
    const[PostDescription, setPostDescription]=useState('');
    const[BookingPrice, setBookingPrice]=useState('');
    const[imagefile, setimagefile]=useState();
    const[PostThumb, setPostThumb]=useState(null);
    console.log(PostTitle)


    const[FirstName, setFirstName]=useState('');
    const[LastName, setLastName]=useState('');
    const[PhoneNumber, setPhoneNumber]=useState();
    const[Email, setEmail]=useState('');
    const[OfficerTitle, setOfficerTitle]=useState('');
    const[Officer, setOfficer]=useState(null);
    const[Password, setPassword]=useState('');
    const[RenterPassword, setRenterPassword]=useState('');
    const[PasswordShow, setPasswordshow]=useState(false);
    const[Category, setCategory]=useState('');
    const[File, setFile]=useState(null);
    const[ManyFiles,setManyFiles]=useState(null);
    const[filetitle, setFiletitle]=useState('');

    const[EditingModal,  setEditingModal]=useState(false);
     const[EditingFirstName, setEditingFirstName]=useState('');
     const[EditingLastName, setEditingLastName]=useState('');
     const[EditingEmail, setEditingEmail]=useState('');
     const[EditingPnumber, setEditingPnumber]=useState('');

     const[loadingModal, setloadingModal]=useState(false);

     const dispatch = useDispatch();
    // function for Editing
    const[Buttondata, setButtonData]=useState('');
    const[DocId, setDocId]=useState('');
    const EditingData = async ({data})=>{
      setFirstName(data.FirstName);
      setLastName(data.LastName);
      setEmail(data.Email);
      setPhoneNumber(data.PhoneNumber);
      setCategory(data.Category);
      setPassword(data.Password);
      setRenterPassword(data.Password);
      setDocId(data.DocumentId);
      setOfficerTitle(data.Title);
      setimagefile(data.Profile)
      setButtonData('Edit & Save')
    }

    function createData(FirstName, LastName, PhoneNumber, Email, Category) {
      return { FirstName, LastName, PhoneNumber, Email, Category };
     }

     const rows = [];
     const [Userdata, setUserData] = useState([]);

     // adminisrtator Phone Number Verification

     const VerifyAndSendVerificationCode = async()=>{
      
     }


     // Adding file to firestore torage
     const [percent, setPercent] = useState(0);
     const UploadImageToStorage = async()=>{
      if (!File) {
       return toast.error(
        "Image File is Missing, please Choose Image"
      )
    }
 else{
  if(ActivityName==='Add-Users'){
    const storageRef = ref(Storage,`${PhoneNumber}/files/${uuidv4()}}`)
  const uploadTask = uploadBytesResumable(storageRef, File);

  uploadTask.on(
      "state_changed",
      (snapshot) => {
          const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );

          // update progress
          setPercent(percent);
      },
      (err) => console.log(err),
      () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              setOfficer(url)
          }).then(
            setloadingModal(false),
            setimagefile(),
            toast.success("Image Uplaoded Succesfuly")
          )
      }
  ); 




  }
  else{
    const storageRef = ref(Storage,`/files/${uuidv4()}}`)
  const uploadTask = uploadBytesResumable(storageRef, File);

  uploadTask.on(
      "state_changed",
      (snapshot) => {
          const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );

          // update progress
          setPercent(percent);
      },
      (err) => console.log(err),
      () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              console.log(url);
              setPostThumb(url)
          }).then(
            setloadingModal(false),
            setimagefile(),
            toast.success("Image Uplaoded Succesfuly")
          )
      }
  ); 




  }
  
 }
   
     }
     // handle formcontrol
     const handleformcontrol = async(e)=>{
      e.preventDefault();
      setCategory(e.target.value)
     }

     // emptying variables

     const VariableEmptying = async()=>{
      setFirstName('');
      setLastName('');
      setEmail('');
      setPassword('');
      setRenterPassword('');
      setCategory('');
      setPhoneNumber(0);
      setButtonData('');
     }

     const UpdateUser = async()=>{
      if(FirstName!=='' && LastName!=='' && Category!=='None' && Category!=='' && PhoneNumber!=='' && Email!=='' && OfficerTitle!==''){
        await Dbconn.collection('DataSecurity').doc('UsersCridentials').collection('Users').doc(DocId).update({
          "User.FirstName":FirstName,
          "User.LastName":LastName,
          "User.Email":Email,
          "User.Phone_Number":PhoneNumber,
          "User.Category":Category,
          "User.UserName":PhoneNumber,
          "User.Profile":Officer,
          "User.Title":OfficerTitle
        }).then(
          ()=>{
            VariableEmptying();
            setloadingModal(false);
            return toast.success("User Information has been Updated Succesfully")
          }
        )
      }
      else{
        return toast.success("Some filed are empty")
      }
     
     }
     // Add user to our store

     const AddUser = async()=>{
      if(FirstName!=='' && LastName!=='' && Category!=='None' && Category!=='' && PhoneNumber!=='' && Email!=='' && OfficerTitle!=='' && Password===RenterPassword ){
        await Dbconn.collection('DataSecurity').doc('UsersCridentials').collection('Users').where('User.Phone_Number','==',PhoneNumber).get().then(
          async (querySnapshot)=>{
            if(querySnapshot.empty){
              await Dbconn.collection('DataSecurity').doc('UsersCridentials').collection('Users').doc(uuidv4()).set({
                User:{
                  FirstName:FirstName,
                  LastName:LastName,  
                  Phone_Number:PhoneNumber,
                  Category:Category,
                  Email:Email,
                  Password:Password,
                  UserName:PhoneNumber,
                  Officer:Officer,
                  Title:OfficerTitle
                }
              }
                
              ).then(
                ()=>{
                 VariableEmptying();
                 setloadingModal(false);
                  return toast.success("User Registed Successfuly")
                }
              )

            }
            else if(!querySnapshot.empty){
              VariableEmptying();
              setloadingModal(false);
              return toast.error('User already Registered')
            
            }
          }
        )
       
      }
      else if(Password!==RenterPassword){
        setloadingModal(false);
        return toast.error("Password Missmatch")
      }
      else if(Category==='None'){
        setloadingModal(false);
        return toast.error("Choose User Category")
      }
      else{
        setloadingModal(false);
      return toast.error("Some Field are  not filled, please check and try again.")
      }

     }
     // adding Post to my store

     const AddingPostsToStore = async()=>{
      if(PostTitle!=='' && PostDescription!=='' && PostThumb!==null){
        await Dbconn.collection('DataSecurity').doc('UserCridentials').collection('AllGoGisenyiToursPosts').doc(uuidv4()).set({
         Post:{
          PostTitle:PostTitle,
          PostThumb:PostThumb,
          PostDescription:PostDescription,
          Available:'Available',
         } 
        }).then(
          ()=>{
            VariableEmptying();
            setloadingModal(false)
            return toast.success("Post Well Saved")
            
          }
        )
      }
      else{
        setloadingModal(false);
        return toast.error("Some Content are Missing check the field and try again.")
      }
     }
    
     const GetImage = (event)=>{
if(event.target.files && event.target.files[0] && ActivityName!=='Add-Users'){
  setimagefile(URL.createObjectURL(event.target.files[0]))
  setFile(event.target.files[0])
}
else if(event.target.files && event.target.files[0] && ActivityName==='Add-Users'){
  setimagefile(URL.createObjectURL(event.target.files[0]))
  setFile(event.target.files[0])
}
     }
     const [AllImages, setAllImages]=useState('');
     const GetMultiImage = (event)=>{
      if(AllImages.length!=0){
        let Ok = window.confirm('The selected Image will be deleted');
        if(Ok){
          setAllImages('');
          setFiletitle('');
          if(event.target.files){
            if(event.target.files){
              let img=URL.createObjectURL(event.target.files[0]);
              let a = event.target.files[0]
              setAllImages(img)
              setManyFiles(a)
            }
          }
       
          else{
            return
          }
        }
        else{
          if(event.target.files){
            if(event.target.files && AllImages.length==0){
              let img=URL.createObjectURL(event.target.files[0]);
              let a = event.target.files[0]
              setAllImages([img])
              setManyFiles(a)
            }
          }
       
          else{
            return
          }
        }
      }
      if(event.target.files){
        if(event.target.files){
          let img=URL.createObjectURL(event.target.files[0]);
          let a = event.target.files[0]
          setAllImages(img)
          setManyFiles(a)
        }
      }
   
      else{
        return
      }
      // else if(event.target.files && event.target.files[0]){
      //   setimagefile(URL.createObjectURL(event.target.files[0]))
      //   setFile(event.target.files[0])
      // }
           }
             const [Progress ,setProgress]=useState('');
           const SaveBoaImages = async()=>{
          
              const storageRef = ref(Storage,`/HomeboardImages/${uuidv4()}}`)
              const uploadTask = uploadBytesResumable(storageRef, ManyFiles);    
              uploadTask.on(
                  "state_changed",
                  (snapshot) => {
                      const percent = Math.round(
                          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                      );
            
                      // update progress
                      setPercent(percent);
                  },
                  (err) => console.log(err),
                  async() => {
                      // download url
                      getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                            Dbconn.collection('HomBOardImagesSlider').doc(uuidv4()).set({
                              'Image':url,
                              title:filetitle,
                            }).then(
                              setManyFiles(null),
                              setAllImages(''),
                              setFiletitle(''),
                              setloadingModal(false),
                              dispatch(setAllBoardImages()),
                              toast.success("Image Uplaoded Succesfuly"),
                              async()=>{
                               await Dbconn.collection('HomBOardImagesSlider').get().then(
                                  (querysnapshot)=>{
                                    if(!querysnapshot.empty){
                                      let All=[];
                                     querysnapshot.forEach((doc)=>{
                                      let value={
                                        id:doc.id,
                                        data:doc.data().Image,
                                        title:doc.data().title,
                                      }
                                      console.log(value);
                                      All.push(value)
                                     })
                                     if(All.length!=0){
                                      dispatch(setAllBoardImages(All))
                                     }
                                    }
                                    else{
                                      return;
                                    }
                                  }
                                )
                                  
                              }
                              
                            )
                          
                          
                        
                        
                       
                      })
                   

                  }
              ); 
            
            
          
           }

  return (
    <div className='flex flex-col flex-1'>
    <ToastContainer position="top-center" limit={1} />
      {ActivityName==='Users'?
      <div>
      <TableContainer component={Paper}>
        <Table  aria-label="simple table" stickyHeader>
        <TableHead>
          <TableRow>
          <TableCell align='left'>Number</TableCell>
            <TableCell align='left'>Firt Name</TableCell>
            <TableCell align='left'>Last Name</TableCell>
            <TableCell align='left'> Phone Number</TableCell>
            <TableCell align='left'>Email</TableCell>
            <TableCell align='left'>Category</TableCell>
            <TableCell align='left'>Modify</TableCell>
            <TableCell align='left'>Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
         {Activity.map((data, i) => (
           <TableRow key={i}>
             <TableCell component="th" scope="row">
               {i}
             </TableCell>
             <TableCell align="left">{data.FirstName}</TableCell>
             <TableCell align="left">{data.LastName}</TableCell>
             <TableCell align="left">{data.PhoneNumber}</TableCell>
             <TableCell align="left">{data.Email}</TableCell>
             <TableCell align="left">{data.Category}</TableCell>
             <TableCell align='left'><Button
             onClick={()=>{
              dispatch(ActivityNames('Add-Users'))
              EditingData({data})
             }}
             className='w-fit mt-1 mb-1 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
             >Modify</Button></TableCell>
            <TableCell align='left'><Button
            onClick={async()=>{
              let OK=window.confirm("You are About to Delete this user, do you want to continue?");
              if(OK){
               await Dbconn.collection('DataSecurity').doc('UsersCridentials').collection('Users').doc(data.DocumentId).delete(); 
              }
            }}
            className='w-fit mt-1 mb-1 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
            >Delete</Button></TableCell>
           </TableRow>
         ))}
       </TableBody>
        </Table>
      </TableContainer>
        </div>

      :ActivityName==='Add-Users'?
    <div className='flex flex-row justify-around px-10 pr-10 mb-20'>
     <div className='px-4 pr-4 flex flex-1 flex-col justify-between Phone:hidden tablet:flex laptop:flex'>
      <div className='flex justify-around flex-col items-start'>
      <label className='font-bold text-2xl mb-2 text-gray-600'>First Name</label>
      <input 
      className='border p-2 rounded-md mt-1 mb-2 w-1/2'
      value={FirstName}
      onChange={(e)=>setFirstName(e.target.value)}
      placeholder='First Name' required/>
      </div>

      <div className='flex justify-around flex-col items-start p-2'>
      <label className='font-bold text-2xl mb-2 text-gray-600'>Last Name</label>
      <input
      className='border p-2 rounded-md mt-1 mb-2 w-1/2'
      value={LastName}
      onChange={(e)=>setLastName(e.target.value)}
       placeholder='Last Name' required/>

      </div>
      <div  className='flex justify-around flex-col items-start p-2'>
        <label className='font-bold text-2xl mb-2 text-gray-600'>Phone Number</label>
        <input 
        className='border p-2 rounded-md mt-1 mb-2 w-1/2'
        value={PhoneNumber}
        onChange={(e)=>setPhoneNumber(e.target.value)}
        placeholder='Telephone Number' type='number'/>
      </div>

      <div className='flex justify-around flex-col items-start p-2'>
      <label className='font-bold text-2xl mb-2 text-gray-600'>Email</label>
      <input 
      className='border p-2 rounded-md mt-1 mb-2 w-1/2'
       value={Email}
       onChange={(e)=>setEmail(e.target.value)}
       placeholder='email' type='email' required/>
      </div>
      
      <div className='flex justify-around flex-col items-start p-2'>
      <label className='font-bold text-2xl mb-2 text-gray-600'>Password</label>
       <input
       type={PasswordShow?'text':'password'}
       className='border p-2 rounded-md mt-1 mb-2 w-1/2'
       value={Password}
       onChange={(e)=>setPassword(e.target.value)}
       placeholder='Password' required/>

      </div>
     
      <div  className='flex justify-around flex-col items-start p-2'>
      <label className='font-bold text-2xl mb-2 text-gray-600'>Re-enter Password</label>
     <div className='flex items-center justify-between w-1/2'>
     <input 
       type={PasswordShow?'text':'password'}
       className='border p-2 rounded-md mt-1 mb-2 mr-2 flex-1'
       value={RenterPassword}
       onChange={(e)=>setRenterPassword(e.target.value)}
       placeholder='Re-enter-password'/>
       {!PasswordShow?<VisibilityIcon onClick={()=>setPasswordshow(!PasswordShow)} sx={{fontSize:30}}/> : <VisibilityOffIcon onClick={()=>setPasswordshow(!PasswordShow)} sx={{fontSize:30}}/>}
     </div>
      </div>
      <div>

      </div>
     <div className='w-fit mt-2 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded self-center'>
     <Button
     onClick={()=>{
      setloadingModal(!loadingModal);
      if(Buttondata===''){
        AddUser();
      }
      else if(Buttondata!==''){
        UpdateUser();
      }
     }}
     sx={{padding:1, backgroundColor:'whitesmoke'}}>{Buttondata==''?'Save a User':Buttondata}</Button>
     </div>
      
      
       
       

     </div>
     <div className='flex flex-col w-1/2 mt-4 '>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">User</InputLabel>
        <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={Category}
    label="Age"
     onChange={handleformcontrol}
  >
    <MenuItem value={"None"}>None</MenuItem>
    <MenuItem value={"Administrator"}>Administrator</MenuItem>
    <MenuItem value={"Officer"}>Officer</MenuItem>
    <MenuItem value={"Worker"}>Worker</MenuItem>
    <MenuItem value={"User"}>User</MenuItem>

  </Select>

      </FormControl>
      <div className='flex flex-col  w-fit mx-4 m-4'>
        <div className='self-center border flex flex-col items-center'>
          <div className='w-fit mt-2 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>
          <input type='file'
          accept='image/*'
          onChange={GetImage}/>
          <Button
          onClick={()=>{
            setloadingModal(!loadingModal);
            UploadImageToStorage();
          }}
          className='bg-gray-500 m-1 p-2 rounded-md'
          >Save Profile</Button>
          </div>
        <Avatar src={imagefile}  sx={{width:240, height:240, alignSelf:'center', justifyItems:'center', alignItems:'center'}}/>  
        </div>
            <div className='self-center mt-4 mb-2'>
              <h1 className='font-semibold text-center text-gray-700 text-2xl mt-2 mb-2'>{FirstName + "  " + LastName}</h1>
              <input 
               className='border p-2 rounded-md mt-1 mb-2 w-full outline-none'
                value={OfficerTitle}
                 onChange={(e)=>setOfficerTitle(e.target.value)}
                placeholder='Officer Title' type='email' required/>
            </div>
            </div>
     </div>
      </div>


      :ActivityName=='All-Posts'?
      <div>
        <ActivityContainer
        ButtonText="Edit"
        />
      </div>

      :
      ActivityName==='Post-a-Journey'?
      <div className='flex items-center w-full flex-col px-5 pr-5'>
        <h1 className='font-bold text-xl mt-4 mb-4 '>New Post or Journey</h1>
        <div className='flex flex-row justify-between mr-5 mx-5 items-center w-full'>
          <div className='flex-1 mr-10'>
          <h1 className='font-bold text-2xl mb-2 text-gray-600 text-start'>Journey Title</h1>
        <input
        value={PostTitle}
        onChange={(e)=>setPostTitle(e.target.value.toUpperCase())}
        className='w-full p-4 border  mt-5 mb-4'
        placeholder='Journey Title'/>
            </div>
            <div>
            <h1 className='font-bold text-2xl mb-2 text-gray-600 text-start'>Choose a date of a Journey</h1>
            <DateTimePicker/>
            </div>
           

        </div>
        <div className='w-fit mt-2 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>
        <input 
        onChange={GetImage}
        accept='image/*'
        type='file'/>
        </div>
       
       

<label className='font-bold text-2xl mb-2 mt-2 text-gray-600'>Post Images </label>
        {imagefile==null?<h1>choose image</h1>:

        <img src={imagefile} className='w-auto h-72'/>}
         <div className={PostThumb==null?'w-fit mt-2 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded':'w-fit mt-2 mb-4 text-white font-bold py-2 px-4 rounded'}>
        {PostThumb==null? <Button
         onClick={()=>{
          setloadingModal(!loadingModal);
          UploadImageToStorage();
         }}
         >Save Post Image</Button>:<CheckCircleIcon 
         onClick
         sx={{color:'green'}}/>}
         </div>
      
      
          <label className='font-bold text-2xl mb-2 text-gray-600'>Post or Journey Description </label>
          <textarea 
          value={PostDescription}
          onChange={(e)=>setPostDescription(e.target.value)}
        className='p-10 text-gray-600 w-full border mx-10 mr-10 h-48 mt-4 text-lg'
        placeholder='Post Description'/>
       
       <div className='w-fit mt-2 mb-16 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>
       <Button
        onClick={()=>{
          setloadingModal(!loadingModal);
          AddingPostsToStore();
        }}
        sx={{backgroundColor:'whitesmoke', padding:1, fontWeight:'normal', fontSize:14, margin:5, border:0.5}}
        >Save Post</Button>
       </div>
        
      </div>
      :
      ActivityName=="ChangeBoard_Images"?
      <div className=' overflow-x-scroll h-96 pb-16'>
        <h1 className='text-center  text-gray-700 font-bold mt-2 mb-4'>Change Board Images</h1>
         <div>
         <div className='flex flex-row items-center justify-between'>
         <div className='w-fit mt-2 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>
        <input 
        onChange={GetMultiImage}
        accept='image/*'
        type='file'/>

       
        </div>
        <Button
         style={{fontSize:12, fontWeight:'bold', padding:10, backgroundColor:'blue',color:'white', borderRadius:5, margin:10}}
        
        onClick={()=>{
          if(ManyFiles!=null){
            let Ok =window.confirm('Dou you want to save all this Images ?');
            if(Ok){
              setloadingModal(!loadingModal);
              SaveBoaImages()
            }
            else{
              return
            }
           
          }
          else{
            alert('Please Select More than one Image')
          }
      
        }}
        >
          Save All Images
        </Button>

        <Button
         style={{fontSize:12, fontWeight:'bold', padding:10, backgroundColor:'green',color:'white', borderRadius:5, margin:10}}
        onClick={()=>{
          let Ok = window.confirm('you want to delete all this seleted Images ?')
          if(Ok){
            setAllImages([])
          }
          else{
            return
          }
          
        }}
        >Delete Images</Button>
        </div>

        <div className='flex flex-row justify-evenly items-center flex-wrap'>
          {AllImages.length!=0?
          <div>
          <img src={AllImages} className='w-auto h-72'/>
          <div className='self-center mt-4 mb-2'>
          <h1 className='font-semibold text-center text-gray-700 text-2xl mt-2 mb-2'>Details</h1>
          <input 
           className='border p-2 rounded-md mt-1 mb-2 w-full outline-none'
            value={filetitle}
             onChange={(e)=>setFiletitle(e.target.value)}
            placeholder='Details'  required/>
        </div>
        </div>
          :<h1 style={{fontSize:10, marginTop:2, marginBottom:4, fontWeight:'bold'}}>Please choose Images</h1>}
        </div>


        <h1 className='text-center font-bold mb-4 mt-2 '>All Home Board Images</h1>

        <div className='border flex flex-row justify-evenly flex-wrap bg-gray-200'>
          {AllBoardImages!=[] && AllBoardImages!=null && AllBoardImages!=undefined?
          AllBoardImages.map((data)=>(
            <div>
            <img src={data.data} className='w-auto h-72'/>
            <div className='flex flex-row items-center flex-1'>
            <input placeholder=''
            className='border p-2 rounded-md mt-1 mb-2 flex-1 outline-none'
            disabled={true}
            value={data.title}
            />
            <Button
            style={{fontSize:12, fontWeight:'bold', padding:10, backgroundColor:'green',color:'white', borderRadius:5, margin:10}}
            onClick={()=>{
              let Ok = window.confirm('do you want to delete this Images')
              if(Ok){
                 Dbconn.collection('HomBOardImagesSlider').doc(data.id).delete();
              }
              else{
                return;
              }
            }}
            >Delete</Button>
            </div>
            </div>
          )):null}
        </div>
          </div>
      </div>

      :
      <h1 className='font-bold text-center text-gray-500 text-6xl'>You Home Please Choose What to Deal With</h1>
      }

{/* editing Modal */}
      <OtherModal
      className=' bottom-1/2 top-40 right-5 left-1/2 sticky z-50 p-5 bg-gray-400  w-fit'
      onRequestClose={()=>setEditingModal(!EditingModal)}
      header={'Edit'}
      isOpen={EditingModal}
      appElement={document.getElementById('app')}
      ariaHideApp={false}
      >
        <div>
          <input
          value={EditingFirstName}
          onChange={(e)=>setEditingFirstName(e.target.value)}
          placeholder='First Name'/>
          <input
          value={EditingLastName}
          onChange={(e)=>setEditingLastName(e.target.value)}
          placeholder='Last Name'/>
          <input
          value={EditingPnumber}
          onChange={(e)=>setEditingPnumber(e.target.value)}
          placeholder='Phone Number'/>
          <input
          value={setEditingEmail}
          onChange={(e)=>setEditingEmail(e.target.value)}
          placeholder='Email'/>
        </div>

        <Button>
          Edit and Save
        </Button>

      </OtherModal>

      <OtherModal
  className=' bottom-1/2 top-64 right-5 left-1/2 sticky z-50 p-5 w-fit h-fit pr-4 px-4'

     header={'Edit'}
     isOpen={loadingModal}
     appElement={document.getElementById('app')}
     ariaHideApp={false}>
<div className='flex  flex-1 self-center items-center justify-center'>
<ClipLoader
color='cyan'
size={100}
/>
</div>
 </OtherModal>
    </div>
  )
}

export default Activity