import  Firebase from "firebase/compat/app";
import  "firebase/compat/firestore";
import {getStorage} from 'firebase/storage';
import "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyDQxvYqcX8KFqVZBDIP2WLhaKKeqDssGJ8",
  authDomain: "gogisenyitours-2be04.firebaseapp.com",
  projectId: "gogisenyitours-2be04",
  storageBucket: "gogisenyitours-2be04.appspot.com",
  messagingSenderId: "704016486375",
  appId: "1:704016486375:web:b3a006035e7e8a8125b742",
  measurementId: "G-F2YWHX6QMZ"
  };



  const Appconnection=Firebase.initializeApp(firebaseConfig);

  const Dbconn=Appconnection.firestore();
  export const Storage = getStorage(Appconnection);
  export {Dbconn};