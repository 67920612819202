import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SelectActivityName } from '../../features/ActivityNameSlice';
import { Selectuser } from '../../features/UserSlice';
import Activity from './Activity';


function WorkingPanel() {
  const [UserRight, setUserRight]=useState('');
  const User = useSelector(Selectuser);
  const ActivityName = useSelector(SelectActivityName);
  useEffect(()=>{
    if(User!=null){
      setUserRight(User.User.Category)
    }
    else {
      setUserRight('')
    }
  },[User,UserRight]);

  // useEffect getting all data from Database if Administrator is logged in

  useEffect(()=>{
   (async()=>{
    if(UserRight==='Administrator'){
      alert('welcome')
    }

   })();
  },[UserRight])
  return (
    <div >
       {UserRight==='Administrator'?
       // administrator division
      <div>
        {ActivityName===''?
        <div className='flex self-center items-center justify-center'><h1 className='font-bold text-center text-4xl text-gray-600'>Select An Activity to work on</h1></div>
        :
        <div className='flex flex-col'> 
        <div className='mb-1'>
        <h1 className='font-bold pt-2 pb-3 text-2xl'>{ActivityName}</h1>
          </div>
          
          {/* data from database displayer */}
        <div className='flex flex-1 mt-1 mb-2'>
          <Activity/>
          </div>  
        </div>}
       
      </div> 
      :
      null
      }
    </div>
  )
}

export default WorkingPanel