import React, { useState } from 'react';
import {faChevronDown,faHome,faContactCard,faCircleInfo,faEye,faUser, faUserAlt} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Alert, Avatar, Button, Modal, colors} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import DehazeIcon from '@mui/icons-material/Dehaze';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import OtherModal from "react-modal";
import CloseIcon from '@mui/icons-material/Close';
import { Dbconn } from '../ConnectionToDatabase/Connect';
import { useDispatch, useSelector} from 'react-redux';
import { Selectuser, UserLogin, Userlogout } from '../../features/UserSlice';
import RiseLoader from "react-spinners/RiseLoader";
import Logo from '../Images/logo.JPG';
import { ActivityNames, SelectActivityName } from '../../features/ActivityNameSlice';
import { useNavigate } from 'react-router-dom';


function Header() {
  const [LoginModal, setLoginModal]=useState(false);
  const[PasswordHide, setPasswordHide]=useState(true);
  const[Changer, setChanger]=useState(true);
  const[loadingspinner, setloadingspinner]=useState(false);

  // selecting user from store
  const dispatch = useDispatch();
  const LoggedInUser= useSelector(Selectuser);
  const ActvityName=useSelector(SelectActivityName);
  console.log(LoggedInUser);

  // variables that hold cridentials data

  const[UserName, setUsername]=useState("");
  const[Password, setPassword]=useState("");
  const[FirstName, setFirstName]=useState("");
  const[LastName, setLastName]=useState("");
  const[Email, setEmail]=useState("");
  const[PhoneNumber, setPNumber]=useState();
  const[RenterPassword, setRenterPassword]=useState("");
  const[ModalFunction, setmodalFunction] = useState('Login');


  // modal for  navbar when we are on tablet phone and other

  const[SmallSizeModal, setSmallSizeModal]=useState(false);

  // navigator
  const navigate = useNavigate();
 

  const EmptyingField = async ()=>{
    setFirstName("");
    setLastName("");
    setPassword("");
    setRenterPassword("");
    setEmail("");
    setPNumber("");
    setUsername("");
  }

  // loggin or register on our web site

  const UserInfo = async () =>{
    if(ModalFunction==='Login'){
      if(UserName!=="" && Password!==""){
        setloadingspinner(!loadingspinner);
      await Dbconn.collection('DataSecurity').doc('UsersCridentials').collection('Users').where('User.UserName','==',UserName).where('User.Password','==',Password).get().then(
        function GetUserInformation(querySnapshot){

          if(querySnapshot.empty){
           Dbconn.collection('DataSecurity').doc('UsersCridentials').collection('Users').where('User.Email','==',UserName).where('User.Password','==',Password).get().then(
            function GetUserInf(querySnapshot){
              if(!querySnapshot.empty){
                querySnapshot.forEach((doc)=>{
                  dispatch(UserLogin(doc.data()));
                  setLoginModal(false);
                  setUsername("");
                  setPassword("");        
                })

              }
              else{ 
                  alert('no user found')
              }
            }
        
            )
           
          }
          
          else if(!querySnapshot.empty){
            querySnapshot.forEach((doc)=>{
              dispatch(UserLogin(doc.data()));
              setLoginModal(false);
              setUsername("");
              setPassword("");        
            })
          }

        }
      ).catch((err)=>{
        console.log(err)
      });
      setloadingspinner(false);
      }
      else{
        alert('Please fill in the username and password')
      }
      
    }
    else if(ModalFunction==='Register'){
      if(FirstName!=="" && LastName!=="" && PhoneNumber!=="" && Password!=="" && RenterPassword!=="" && Password===RenterPassword){
        alert('now you can register(ubu wa kwiyandikisha)')
      }
      else if(Password!==RenterPassword){
        alert('password not match(Ijambo banga ntirisa)')
      }
      else{
        alert('check if all information are filled in (genzura niba umwirondoro wose wujujwe uko bisabwa)')
      }
    }
    
  }



  return (
    <div className='flex justify-between px-2 pr-2 bg-gray-300 h-28 items-center  top-0 z-50 sticky'>
        {/* header company logo */}
        <div className='flex justify-between flex-1 cursor-pointer'>
            <img
            onClick={()=>{
              dispatch(ActivityNames('/'))
            }}
             alt='logo' className='mx-3 mr-3 w-48 h-48 Phone:hidden tablet:hidden  laptop:block small:hidden'  src={Logo}/>
       
       <h1 className='font-bold text-xl text-gray-600 laptop:hidden'>Go Gisenyi Tours</h1>
        </div>

{/* header links to different services */}
        <div className='flex justify-around mr-4'>
        <div className='flex justify-around mr-4 tablet:hidden Phone:hidden laptop:flex  small:hidden'>
       
            <h1 
             onClick={()=>{
              dispatch(ActivityNames('/'))
            }}
            
            className='flex flex-row cursor-pointer hover:p-1'><h1 className={ActvityName==='/'?'flex flex-row cursor-pointer hover:p-1 text-green-500 font-extrabold mx-4 mr-4':'flex flex-row cursor-pointer hover:p-1 text-white font-bold mx-4 mr-4'}> HOME</h1></h1>
                     <h1 
             onClick={()=>{
              dispatch(ActivityNames('Tours'))
            }}
            className='flex flex-row cursor-pointer hover:p-1'><h1 className={ActvityName==='Tours'?'flex flex-row cursor-pointer hover:p-1 text-green-500 font-extrabold mx-4 mr-4':'flex flex-row cursor-pointer hover:p-1 text-white font-bold mx-4 mr-4'}>TOURS</h1></h1>
             <h1 
        onClick={()=>{
          dispatch(ActivityNames('AboutUs'))
        }}
        className='flex flex-row cursor-pointer hover:p-1'><h1 className={ActvityName==='AboutUs'?'flex flex-row cursor-pointer hover:p-1 text-green-500 font-extrabold mx-4 mr-4':'flex flex-row cursor-pointer hover:p-1 text-white font-bold mx-4 mr-4'}>ABOUT US</h1></h1>
            
            <h1 
             onClick={()=>{
              dispatch(ActivityNames('ContactUs'))
              window.scrollTo(0,10000)
            }}
            className='flex flex-row cursor-pointer hover:p-1'><h1 className={ActvityName==='ContactUs'?'flex flex-row cursor-pointer hover:p-1 text-green-500 font-extrabold mx-4 mr-4':'flex flex-row cursor-pointer hover:p-1 text-white font-bold mx-4 mr-4'}> CONTACT US</h1></h1>
      
            <h1 
             onClick={()=>{
              dispatch(ActivityNames('Blog'))
            }}
            className='flex flex-row cursor-pointer hover:p-1'><h1 className={ActvityName==='Blog'?'flex flex-row cursor-pointer hover:p-1 text-green-500 font-extrabold mx-4 mr-4':'flex flex-row cursor-pointer hover:p-1 text-white font-bold mx-4 mr-4'}>BLOG</h1></h1>
       

        </div> 
        <div
        onClick={()=>{setSmallSizeModal(!SmallSizeModal)}}
        className='Phone:flex laptop:hidden tablet:flex'>
        <DehazeIcon sx={{color:'green' , fontSize:30, cursor:'pointer'}} />
        </div> 
         
        </div>



        {/* login information  */}

        <div className='flex items-center mr-2 phone:hidden small:hidden tablet:hidden laptop:flex'>
            <h1 className=' text-yellow-100 font-bold mr-3'>{LoggedInUser!=null  && LoggedInUser!=undefined && LoggedInUser!=null && LoggedInUser!=''? LoggedInUser.User.FirstName + " " + LoggedInUser.User.LastName : 'Log In'}</h1>
            <div
             onClick={()=>{setLoginModal(!LoginModal)}}
            className='border-solid border-black bg-slate-200 p-2 rounded-full cursor-pointer'>
              {LoggedInUser!=null?
              <Avatar src={LoggedInUser.User.Profile} sx={{width:36, height:36}}/>:
              <FontAwesomeIcon  icon={faUserAlt} size='xl'/>
              }
            </div>
            
        </div>


        <OtherModal
        className=' bottom-1/2 top-40 right-5 left-1/2 sticky z-50 p-5 bg-gray-400  w-fit'
        onRequestClose={()=>setLoginModal(!LoginModal)}
        header={'Login'}
        isOpen={LoginModal}
        appElement={document.getElementById('app')}
        ariaHideApp={false}>        
          {LoggedInUser==null?

<div className=' w-96'>
<div  className='cursor-pointer bg-slate-300 border border-r-1 rounded-full w-fit hover:bg-white p-1'>
  <CloseIcon 
  sx={{color:'red'}}
  onClick={()=>{setLoginModal(!LoginModal); setmodalFunction('Login'); setUsername(""); setPassword("")}}
    />
    
    </div> 
      
{ModalFunction==="Login"?
<div className='flex flex-col z-50'>
<h1 className='text-center  mt-2 mb-4 font-bold text-sm'>Go Gisenyi Tours</h1>
<h1 className=' text-left mt-4 mb-2 font-bold text-xs'>User name (Email II Phone)</h1>
<div className='flex flex-row justify-between items-center'>
<input 
type={Changer===true ? 'email' : 'number'}
value={UserName}
onChange={(e)=>setUsername(e.target.value)}
className='w-4/5 border-solid border border-r px-2 pr-4 pt-1 pb-1 border-gray-500 outline-0 cursor-text rounded-md'
placeholder={Changer===true ? 'E-mail' : 'Phone number'}
/>
<div onClick={()=>{setChanger(!Changer); setUsername("")}}>
{Changer===true?<EmailIcon/> : <PhoneIcon/>}
</div>

</div>
<h1 className=' text-left mt-4 mb-2 font-bold text-xs'>Password</h1>
<div className='flex flex-row justify-between items-center'>
<input
value={Password}
onChange={(e)=>setPassword(e.target.value)}
 className=' w-4/5 border-solid border border-r px-2 pr-4 pt-1 pb-1 border-gray-500 outline-0 cursor-text rounded-md'
type={PasswordHide===true? 'password' :'text'}
placeholder='Enter your Password'/>

{Password!==""?
<div onClick={()=>setPasswordHide(!PasswordHide)}>
{PasswordHide===true?<VisibilityIcon/> : <VisibilityOffIcon/>}
</div>:null 
}
</div>


</div> 

:
<div className='flex flex-col z-50'>
<h1 className='text-center  mt-2 mb-4 font-bold text-lg'>Register</h1>

<div className='flex flex-col'>
<label
className=' text-left mt-4 mb-2 font-bold text-xs'
>Izina ribanza</label>
<input 
value={FirstName}
onChange={(e)=>setFirstName(e.target.value)}
className=' w-4/5 border-solid border border-r px-2 pr-4 pt-1 pb-1 border-gray-500 outline-0 cursor-text rounded-md'
placeholder='First name'/>
<label
className=' text-left mt-4 mb-2 font-bold text-xs'
>Izina rindi</label>
<input 
value={LastName}
onChange={(e)=>setLastName(e.target.value)}
className=' w-4/5 border-solid border border-r px-2 pr-4 pt-1 pb-1 border-gray-500 outline-0 cursor-text rounded-md'
placeholder='Last name'/>
<label
className=' text-left mt-4 mb-2 font-bold text-xs'
>Email</label>
<input 
value={Email}
onChange={(e)=>setEmail(e.target.value)}
className=' w-4/5 border-solid border border-r px-2 pr-4 pt-1 pb-1 border-gray-500 outline-0 cursor-text rounded-md'
placeholder='email'/>
<label
className=' text-left mt-4 mb-2 font-bold text-xs'
>Nimero ya Telephone</label>
<input 
value={PhoneNumber}
onChange={(e)=>setPNumber(e.target.value)}
className=' w-4/5 border-solid border border-r px-2 pr-4 pt-1 pb-1 border-gray-500 outline-0 cursor-text rounded-md'
placeholder='Phone Number'
type={'number'}/>
<label
className=' text-left mt-4 mb-2 font-bold text-xs'
>Ijambo Banga</label>
<input
type={PasswordHide===true? 'password' : 'text'}
value={Password}
onChange={(e)=>setPassword(e.target.value)}
className=' w-4/5 border-solid border border-r px-2 pr-4 pt-1 pb-1 border-gray-500 outline-0 cursor-text rounded-md'
placeholder='Password'/>
<label
className=' text-left mt-4 mb-2 font-bold text-xs'
>Subizamo ijambo banga</label>
<input
type={PasswordHide===true ? 'password' : 'text'}
value={RenterPassword}
 onChange={(e)=>setRenterPassword(e.target.value)}
className=' w-4/5 border-solid border border-r px-2 pr-4 pt-1 pb-1 border-gray-500 outline-0 cursor-text rounded-md'
placeholder='Re-enter password'/>
{Password!==""?
<div className=' w-fit p-2' onClick={()=>setPasswordHide(!PasswordHide)}>
{PasswordHide===true ? <VisibilityIcon/> : <VisibilityOffIcon/>}
</div>:null
}
 
</div>
</div>

}

<div className='flex justify-between mt-5'>
  {/* <Button
  onClick={()=>{
    if(ModalFunction==='Login'){
      setmodalFunction('Register');
    }
    else{
      setmodalFunction('Login');
    }

    EmptyingField();
   }}
  >{ModalFunction==='Login'? 'Register' : 'go Back'}</Button> */}
  {loadingspinner===true ? <RiseLoader
  color='cyan'
  size={10}
  />: false}
  <Button
  onClick={UserInfo}
  >{ModalFunction==='Login'? 'Login' : 'Register'}</Button>
</div>
</div>
        :
        <div className='flex w-96 bg-gray-50 p-3 justify-between'>
          <Button
          onClick={()=>{
            dispatch(ActivityNames('/'));
           dispatch(Userlogout());
           setLoginModal(false);
          
          }}
          >Log Out</Button>

          <Button
          onClick={()=>{
            if(LoggedInUser.User.Category=='Administrator'){
              setLoginModal(false);
               navigate('Admin')
            }
          }
           }
          >My panel</Button>

          <Button
          onClick={()=>{
            setLoginModal(false);
          }}
          >Cancel</Button>
        </div>  
        }
        </OtherModal>



        {/* small size  modal */}
        <OtherModal
        className='w-fit self-center bottom-1/2 right-0 left-0 top-28 sticky z-50 p-5 bg-gray-300 laptop:hidden border-none outline-none'
        onRequestClose={()=>setSmallSizeModal(!SmallSizeModal)}
        header={'Login'}
        isOpen={SmallSizeModal}
        appElement={document.getElementById('app')}
        ariaHideApp={false}
        >
          <div className='flex flex-col laptop:hidden justify-between items-center self-center w-auto'>
            <h1 
             onClick={()=>{
              setSmallSizeModal(!SmallSizeModal);
              dispatch(ActivityNames('/'))
            }}
            
            className='flex flex-row cursor-pointer hover:p-1 m-4'><h2 className={ActvityName==='/'?'flex flex-row cursor-pointer hover:p-1 text-green-500 font-extrabold mx-4 mr-4':'flex flex-row cursor-pointer hover:p-1 text-white font-bold mx-4 mr-4'}> HOME</h2></h1>
            
            <h1 
             onClick={()=>{
              setSmallSizeModal(!SmallSizeModal);
              dispatch(ActivityNames('Tours'))
            }}
            className='flex flex-row cursor-pointer hover:p-1'><h2 className={ActvityName==='Tours'?'flex flex-row cursor-pointer hover:p-1 text-green-500 font-extrabold mx-4 mr-4':'flex flex-row cursor-pointer hover:p-1 text-white font-bold mx-4 mr-4'}> TOURS</h2></h1>
           
 <h1 
        onClick={()=>{
          setSmallSizeModal(!SmallSizeModal);
          dispatch(ActivityNames('AboutUs'))
        }}
        className='flex flex-row cursor-pointer hover:p-1 m-4'><h2 className={ActvityName==='AboutUs'?'flex flex-row cursor-pointer hover:p-1 text-green-500 font-extrabold mx-4 mr-4':'flex flex-row cursor-pointer hover:p-1 text-white font-bold mx-4 mr-4'}>ABOUT US</h2></h1>
            <h1 
             onClick={()=>{
              setSmallSizeModal(!SmallSizeModal);
              window.scrollTo(0,10000)
              dispatch(ActivityNames('ContactUs'))
            }}
            className='flex flex-row cursor-pointer hover:p-1 m-4'><h2 className={ActvityName==='ContactUs'?'flex flex-row cursor-pointer hover:p-1 text-green-500 font-extrabold mx-4 mr-4':'flex flex-row cursor-pointer hover:p-1 text-white font-bold mx-4 mr-4'}> CONTACT US</h2></h1>
            <h1 
             onClick={()=>{
              setSmallSizeModal(!SmallSizeModal);
              dispatch(ActivityNames('Blog'))
            }}
            className='flex flex-row cursor-pointer hover:p-1 m-4'><h2 className={ActvityName==='Blog'?'flex flex-row cursor-pointer hover:p-1 text-green-500 font-extrabold mx-4 mr-4':'flex flex-row cursor-pointer hover:p-1 text-white font-bold mx-4 mr-4'}>BLOG</h2></h1>
            

        </div> 
        </OtherModal>
        
      
    </div>
  )
}

export default Header
