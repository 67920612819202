import React, { useEffect } from 'react'
import Footer from './Footer';

function Blog() {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[]);
  return (
    <div className='flex flex-col justify-between '>
      <h1 className='text-gray-500 font-bold text-center mt-4 text-2xl'>Go Gisenyi Tours Blog</h1>
      <Footer/>
    </div>
  )
}

export default Blog
