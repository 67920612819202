import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {persistReducer} from 'redux-persist';
import { combineReducers } from '@reduxjs/toolkit';
import UserReducer from '../features/UserSlice';
import ActivityReducer from "../features/ActivitySlice";
import ActivityNameReducer from '../features/ActivityNameSlice';
import PostReducer from '../features/PostSlice';
import PosterReducer from '../features/PostDisplay';

const persistConfig = {
  key:'root',
  version:1,
  storage
}

const reducer = combineReducers({
  User: UserReducer,
  Activity:ActivityReducer,
  Activityname:ActivityNameReducer,
  Post:PostReducer,
  Poster:PosterReducer
});

const persistingReducer = persistReducer(persistConfig, reducer);
export const store = configureStore({
  reducer:persistingReducer,

});
